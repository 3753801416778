@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,800;0,900;1,100;1,400;1,500;1,600;1,800;1,900&display=swap');


@layer base{
  html{
    @apply scroll-smooth
  }
  body{
    @apply font-[poppins]
  }
  li{
    @apply px-4 text-sm
  }
  button{
    @apply  border bg-gradient-to-tr from-blue-600 
  }
  h2{
    @apply text-xl font-bold
  }
  h3{
    @apply text-2xl 
  }
}