.aligntop{
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonshade{
    box-shadow: -5px 5px 5px gray;
    /* transition: all 1s ease-in; */
    cursor: pointer;
}
.buttondark{
        /* box-shadow: inset 0 0 5px rgb(98, 97, 97); */
        box-shadow: inset 1px -2px 10px 7px gray;
        border:1px solid white !important;
}
.buttonshade:hover{
        /* box-shadow: inset 0 0 5px rgb(98, 97, 97); */
        box-shadow: inset 1px -2px 20px 7px gray;
        border:1px solid white !important;
}
.buttonshadetext:hover{
    scale: 0.9;
}
.social-icon:hover{
    scale: 0.9;
    background: #fff;
    color: black;
}

.skill{
    font-size:40px;
    cursor: pointer;
}
.logoswipe{
    height:200px;
    padding: 0px 5px; 

}
/* breadcumboard */
.ol li{
    padding: 0px;
}